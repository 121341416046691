<template>

    <button :class="buttonClasses">
            <slot></slot>
    </button>

</template>

<script>
export default {
    props: ['button_style', 'button_spec'],
    computed: {
        buttonClasses() {
            if(this.button_spec) {
                return 'button-' + this.button_style + ' ' + 'button-' + this.button_style + '--' + this.button_spec; 
            }
            return 'button-' + this.button_style; 
        }
    }
}
</script>
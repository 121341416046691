<template>
    <section  id="kontakt" class="container container--black">
        <div class="container__inner container__inner--title">
            <h1 class="heading-primary heading-primary--references ">Kontaktné údaje</h1>
        </div>
        <div class="container__inner container__inner--content">
            
            <div class="container__block">
                    <span>Trend Servis s.r.o.</span>
                    <span>Medená 93/10, 811 02 Bratislava</span><br />
                    <span><div class="bold-text">IČO:</div> 31406289,</span>
                    <span><div class="bold-text">IČ DPH:</div> SK2020330884</span>
                    <br />
                    <span><div class="bold-text">email:</div> adam@ivankanet.sk</span>
                    <span><div class="bold-text">mobil:</div> +421 905 219 458</span>
            </div>
            <div class="container__block">
                <h2 class="heading-secondary heading-secondary--bold">Alebo nám napíšte</h2><br/>
                <form class="form-control" @submit.prevent="submitForm">
                    <div class="form-group">
                        <label for="full-name">Meno a priezvisko</label>
                        <input type="text" id="full-name" name="full-name" v-model.trim="fullName" @blur="validateFullName" />
                        <p v-if="fullNameValidity === 'invalid'">Prosím zadajte Vaše celé meno.</p>
                    </div>
                    <div class="form-group">
                        <label for="email">E-mailová adresa</label>
                        <input type="email" id="email" name="email" v-model.trim="email" @blur="validateEmail">
                        <p v-if="emailValidity === 'invalid'">Prosím zadajte Vašu emailovú adresu.</p>
                    </div>
                    <div class="form-group">
                        <label for="message">Vaša správa</label>
                        <textarea rows="4" name="message" id="message" v-model.trim="message" @blur="validateMessage"></textarea>
                        <p v-if="messageValidity === 'invalid'">Prosím zadajte správu, ktorú nám chcete poslať. </p>
                    </div>
                    <div class="form-group">
                        <p class="main main--invalid" v-if="!formValidity">{{ formInvalidMessage }} </p>
                        <p class="main main--valid" v-if="formSuccess">{{ formSuccessMessage }}</p>
                    
                    <button class="button-primary button-primary--contact">Odoslať správu</button></div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
const querystring = require("querystring");

export default{
    data() {
        return {
            fullName: '',
            email: '',
            message: '',
            fullNameValidity: 'pending',
            emailValidity: 'pending',
            messageValidity: 'pending',
            formIsValid: true,
            formValidity: true, 
            formSuccess: false,
            postResults: [],
        }
    },
    computed: {
        formInvalidMessage() {
            if(this.formIsValid) {
                return '';
            } else {
                return 'Prosím vyplňte všetky povinné polia!';
            }
        },
        formSuccessMessage() {
            if(this.formSuccess) {
                return 'Ďakujeme za vašu správu. Budeme sa jej čo najskôr venovať. ';
            } else {
                return '';
            }
        },
    },
    methods: {
        submitForm() {
            this.formSuccess = false;
            this.validateFullName();
            this.validateEmail();
            this.validateMessage();

            if(!this.formIsValid) {
                this.formValidity = false;
            } else {
                this.sendEmail();
                this.formValidity = true;
                this.fullName = '';
                this.email = '';
                this.message =  '';
            }

            
        },
        sendEmail() {
            let url = 'https://trendservis.sk/mailer.php';

            axios.post(url,  
                querystring.stringify({
                    name: this.fullName, 
                    email: this.email,
                    message: this.message
                })
            ).then((response) => {
                const status = response.status;

                if(status == 200){
                    this.formSuccess = true; 
                } else {
                    this.formSuccess = false; 
                }
            });

        },
        validateFullName() {
            if(this.fullName === '') {
                this.fullNameValidity = 'invalid';
                this.formIsValid = false; 
            } else {
                this.fullNameValidity = 'valid';
                this.formIsValid = true; 
            }
        },
        validateEmail() {
            if(this.email === '' || !this.email.includes('@') || !this.email.includes('.')) {
                this.emailValidity = 'invalid';
                this.formIsValid = false; 
            } else {
                this.emailValidity = 'valid';
                this.formIsValid = true; 
            }
        },
        validateMessage(){
            if(this.message === '') {
                this.messageValidity = 'invalid';
                this.formIsValid = false; 
            } else {
                this.messageValidity = 'valid';
                this.formIsValid = true; 
            }
        }
        
    }

}


</script>
import 'babel-polyfill'  
import { createApp } from 'vue';


import App from './App.vue';
// import store from './store/index.js';


import { throttle } from 'lodash/throttle';    
import AOS from 'aos';
import "aos/dist/aos.css";

import CustomButton from './components/Buttons.vue';

const app = createApp(App);

app.component('custom-button', CustomButton);

// app.use(store);
app.use(throttle);
app.use(AOS); 

AOS.init({
    once: true, 

});

app.mount('#app');



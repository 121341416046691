<template>
  <div class="wrapper">
    <custom-navigation></custom-navigation>
    <hero-section></hero-section>
    <about-us-section></about-us-section>
    <references-section></references-section>
    <services-section></services-section>
    
    <contact-us></contact-us>
    <footer-section></footer-section>
    <!-- <section id="o-nas" data-matching-link="#o-nas">O nas</section>
    <section id="referencie" data-matching-link="#referencie">Referencie</section>
    <section id="sluzby" data-matching-link="#sluzby">sluzby</section>
    <section id="kontakt" data-matching-link="#kontakt">Kontakt</section> -->

  </div>
</template>

<script>
import CustomNavigation from './components/Navigation.vue';
import HeroSection from './sections/HeroSection.vue';
import AboutUsSection from './sections/AboutUsSection.vue';
import ServicesSection from './sections/ServicesSection.vue';
import ReferencesSection from './sections/ReferencesSection.vue';
import ContactUs from './sections/ContactUs.vue';
import FooterSection from './sections/Footer.vue';

export default {
  components: {
    CustomNavigation,
    HeroSection,
    AboutUsSection,
    ServicesSection,
    ReferencesSection,
    ContactUs,
    FooterSection
  },
  data() {
    return {
    };
  },
  created() {
  }
};
</script>

<style lang="scss">

@import "./styles/main.scss";

</style>

<style>
</style>
<template>
    
    <section  id="o-nas" class="aboutUs" >
        <!-- <h2 class="heading-secondary heading-secondary--hits">Viac ako</h2> -->
        <div class="aboutUs__hits">
            <div class="aboutUs__block">
                <!-- <h3 class="heading-terciary heading-terciary--hits">Viac ako</h3> -->
                <div class="aboutUs__hit">
                    <div class="aboutUs__number"><h1 class="heading-primary heading-primary--large">{{ animatedYears }}</h1></div>
                    <div class="aboutUs__text">
                        <h2 class="heading-secondary heading-secondary--hits">ÚSPEŠNÝCH <br>ROKOV NA TRHU</h2><br>
                        <span data-aos="fade-up" data-aos-duration="1000">Už od roku 1990 poskytujeme kvalitné služby v oblasti servisu a údržby kotlov. Viac ako 30 rokov skúseností nás zaväzuje k profesionálnemu a odbornému prístupu ku každému klientovi. </span>
                    </div>
                </div>
                <!-- <div class="aboutUs__desc"><span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit quia voluptas saepe hic velit voluptatibus repellat qui, quasi eum maxime cupiditate ab modi enim nulla doloribus, et voluptates deserunt quibusdam.</span></div> -->
            </div>
            <div class="aboutUs__block">
                <!-- <h3 class="heading-terciary heading-terciary--hits">Viac ako</h3> -->
                <div class="aboutUs__hit">
                    <div class="aboutUs__number" ><h1 class="heading-primary heading-primary--large">{{ animatedCrashes }}</h1></div>
                    <div class="aboutUs__text"><h2 class="heading-secondary heading-secondary--hits">VYRIEŠENÝCH <br>HAVÁRIÍ ROČNE</h2><br>
                    <span data-aos="fade-up" data-aos-duration="1000">Počas každej situácie sme vám okamžite k dispozícii. Každý rok uskutočníme viac ako 700 servisných výjazdov v prípade havaríjneho stavu vašich zariadení alebo potreby ich vyregulovaní. </span>
                    </div>
                </div>
            </div>
            <div class="aboutUs__block">
                <!-- <h3 class="heading-terciary heading-terciary--hits">Viac ako</h3> -->
                <div class="aboutUs__hit">
                    <div class="aboutUs__number"><h1 class="heading-primary heading-primary--large">{{ animatedBoilerRooms }}</h1></div>
                    <div class="aboutUs__text"><h2 class="heading-secondary heading-secondary--hits">KOTOLNÍ POD <br>NAŠOU SPRÁVOU</h2><br>
                    <span data-aos="fade-up" data-aos-duration="1000">Servis a zákonné prehliadky plynových kotlov, pretlakových horákov a tepelných čerpadiel poskytujeme aj zmluvným zákazníkom. V súčasnosti spravujeme viac ako 30 veľkých kotolní.</span>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section class="aboutContent">
        <div class="aboutContent__inner">
            <div class="aboutContent__text" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="heading-terciary heading-terciary--us">O nás</h3>
                <span class="highlighted-text highlighted-text--us">Firma “Trend servis” vznikla už v roku 1990 (od roku 1996 spol. s.r.o.). Naše služby poskytujeme v Bratislave a jej okolí (okres Senec, Pezinok a Malacky).</span>
                <span>Naša firma sa dlhodobo zaoberá záručným aj pozáručným servisom, údržbou, montážou, revíziou domácich kotlov, kotlov veľkých výkonov (100KW÷20MW),  pretlakových horákov, tepelných čerpadiel a regulácií vykurovania. Rovnako ponúkame predaj a montáž týchto zariadení. Za 30 rokov sme nazbierali množstvo skúseností s prevádzkou plynových kotlov a kotolní, ktoré nás zaväzujú ku kvalitnej a profesionálnej robote. </span>
            </div>
            <div class="aboutContent__photos" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div class="aboutContent__photo"><img class="aboutUs__photo"  src="../assets/images/us6.jpg" alt="Boiler" /></div>
                <div class="aboutContent__photo"><img class="aboutUs__photo"  src="../assets/images/us7.jpg" alt="Boiler" /></div>
                <div class="aboutContent__photo"><img class="aboutUs__photo"   src="../assets/images/us8.jpg" alt="Heat Pump" /></div>
            </div>
            <!-- <div class="aboutContent__logo" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <img src="../assets/SVG/logo.svg" alt="Trendservis logo">
                <img src="../assets/SVG/logo.svg" alt="Trendservis logo">
            </div> -->
        </div>
    </section>
</template>

<script>
//import AnimatedNumber from '../components/AnimatedNumber.vue';
import gsap from 'gsap';

export default {
    // components: {
    //     AnimatedNumber,
    // },
    data() {
        return {
            years: 0,
            crashes: 0, 
            boilerRooms: 0,
            tweenedYears: 0,
            tweenedCrashes: 0,
            tweenedBoilerRooms: 0,
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        animatedYears: function() {
            return this.tweenedYears.toFixed(0);
        },
        animatedCrashes: function() {
            return this.tweenedCrashes.toFixed(0);
        },
        animatedBoilerRooms: function() {
            return this.tweenedBoilerRooms.toFixed(0);
        }
    },

    watch: {
        years: function(newValue) {
            gsap.to(this.$data, { duration: 1.5, tweenedYears: newValue });
        },
        crashes: function(newValue) {
            gsap.to(this.$data, { duration: 2.5, tweenedCrashes: newValue });
        },
        boilerRooms: function(newValue) {
            gsap.to(this.$data, { duration: 2, tweenedBoilerRooms: newValue });
        },
    },
    created() {
        this.years = 30;
        this.crashes = 700;
        this.boilerRooms = 30;
    },   
}
</script>

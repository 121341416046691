<template>
    <section  id="referencie" class="references">
        <h1 class="heading-primary heading-primary--references">Referencie</h1>
        <div class=references__inner>
            <div class="references__left" data-aos="fade-left" data-aos-duration="1000">
                <img class="references__photo" src="../assets/images/ref1.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref2.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref3.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref4.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref5.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref6.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref7.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref8.png" alt="Boiler" />
                <img class="references__photo" src="../assets/images/ref9.png" alt="Boiler" />
            </div>
            <div class="references__right" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <span class="highlighted-text">Spolupracujeme napríklad s Johson Controls s.r.o., Facility & Property Management s.r.o., Global Facility & Property s.r.o., PPM-IMMO s.r.o., Seting Bratsilava s.r.o., Karlovesská realizačná spoločnosť s.r.o. a viacero SVB.</span>
                <span>Niektoré kotolne, ktoré sme uviedli do prevádzky a aj ich servisujeme: Nám. SNP 10, Bratislava – Milosrdní Bratia, Ďumbierska 3 Bratislava-Národná Trnsfúzna Stanica, Horný Dvor Bernolákovo-Flora Servis, Štefániková Bratislava-Kooperatíva, Panennská 7 Bratislava-AST Odenwald, Viničiarska Cesta 19 Pezinok-DEDOLES,Mlynské Luhy 4 Bratislava-UK KOMENSKÉHU, Chorvátsky Grob-DOM SENIOROV ATLANTÍDA.</span>
            </div>
        </div>

    </section>
</template>
<template>
    <div :class="isNavbarSticky" @scroll="addStickyNavbar">
        <div class="navigation__title">
            <a href="/"><img src="../assets/SVG/logo.svg" alt="Trendservis logo"></a>
            <a href="/"><h2 class="heading-secondary heading-secondary--inNav">TREND SERVIS</h2></a>
        </div>
        <nav class="navigation__list" :class="toggleVisibility">
            
            <!-- <a class="navigation__link" 
               v-for="navItem in this.$store.getters.getNavigation" 
               :key="navItem.id" :href="'#' + navItem.url"
               :class="{isActive: navItem.title === this.activeBlock}"
            ><span>{{ navItem.title }}</span></a> -->
            <div class="navigation__list--content">
                <a href="#" class="navigation__link">{{ navItems.home.title }}</a>
                <a href="#o-nas" class="navigation__link">{{ navItems.aboutUs.title }}</a>
                <a href="#referencie" class="navigation__link">{{navItems.references.title}}</a>
                <a href="#sluzby" class="navigation__link">{{ navItems.services.title}}</a>
                <a href="#kontakt" class="navigation__link">{{navItems.contact.title}}</a>
                <a href="tel:0949949949"><custom-button button_style="primary" button_spec="inNav">0905219458</custom-button></a>
                <!-- <a href="#" class="navigation__link">{{ navItems.home.title }}</a>
                <a href="#o-nas" class="navigation__link" :class="{isActive: navItems.aboutUs.title === this.activeBlock}">{{ navItems.aboutUs.title }}</a>
                <a href="#referencie" class="navigation__link" :class="{isActive: navItems.references.title === this.activeBlock}">{{navItems.references.title}}</a>
                <a href="#sluzby" class="navigation__link" :class="{isActive: navItems.services.title === this.activeBlock}">{{ navItems.services.title}}</a>
                <a href="#kontakt" class="navigation__link" :class="{isActive: navItems.contact.title === this.activeBlock}">{{navItems.contact.title}}</a>
                <a href="tel:0949949949"><custom-button button_style="primary" button_spec="inNav">0905219458</custom-button></a> -->
            </div>
            
            
            
        </nav>
        <div class="navigation__hamburger" :class="toggleHamburger" @click="toggleMenu">
            <span :class="toggleHamburger"></span>
            <span :class="toggleHamburger"></span>
            <span :class="toggleHamburger"></span>
        </div>

    </div>
</template>

<script>

import throttle from 'lodash/throttle'
//import debounce from 'lodash/debounce'

export default {
    data() {
        return {
            navItems: {
                'home': {
                    id: 1,
                    title: 'Domov',
                    url: '#'
                },
                'aboutUs': {
                    id: 2,
                    title: 'O nás',
                    url: '#o-nas'
                },
                'references': {
                    id: 3,
                    title: 'Referencie',
                    url: '#referencie'
                },
                'services': {
                    id: 4,
                    title: 'Služby',
                    url: '#sluzby'
                },
                'contact': {
                    id: 5,
                    title: 'Kontakt',
                    url: '#kontakt'
                },
            },
            isSticky: false, 
            menuVisibility: false,
            activeBlock: null,
            blockEls: [],
            idBlocks: [],
            blockBreakpoints: [],
        };
    },
    computed: {
        toggleVisibility() {
            if(this.menuVisibility === true) {
                return 'navigation__list--visible';
            } 
            return '';
        },
        toggleHamburger() {
            if(this.menuVisibility === true) {
                return 'toggled';
            } 
            return '';
        },
        isNavbarSticky(){
            if(this.isSticky) {
               return 'navigation navigation--sticky';
            }
            return 'navigation';
        },
    }, 
    methods: {
        addStickyNavbar() {
            if (window.scrollY > 50) {
                this.isSticky = true;
            } else {
                this.isSticky = false;
            }
        },
        toggleMenu() {
            this.menuVisibility = !this.menuVisibility;
        },
        calculateAdjustedBlockPositions() {
            this.idBlocks = ['domov', 'o-nas', 'referencie', 'sluzby', 'kontakt'];

            this.blockEls = this.idBlocks.map(el => document.getElementById(el));
            
            const viewportHeight = window.innerHeight;
            const docHeight = document.body.offsetHeight;
            
            // calculate block breakpoints for each block
            const scrollTop = document.documentElement.scrollTop;
            this.blockBreakpoints = this.blockEls.map(el => {
                const top = el.getBoundingClientRect().top;
                const oldOffset = top + scrollTop;
                return oldOffset - oldOffset * (viewportHeight / docHeight);
            })
           
        },
        initObserver() {
            const options = {
            // create an array of thresholds from .00 to .99
            threshold: [...Array(10)].map((el,index) => .1 * index),
            }
            
            const handleIntersectionEvent = () => {
            // treat this much like an onscroll handler
            const scrollTop = document.documentElement.scrollTop;
            this.blockBreakpoints.find((bp,index) => {

                if (Math.abs(scrollTop - bp) < 100) {
                    if(index === 0) {
                        this.activeBlock = 'Domov';
                    } else if(index === 1) {
                        this.activeBlock = 'O nás';
                    } else if(index === 2) {
                        this.activeBlock = 'Referencie';
                    } else if(index === 3) {
                        this.activeBlock = 'Služby';
                    } else if(index === 4) {
                        this.activeBlock = 'Kontakt';
                    }
                }
            })
            }
            
            const observer = new IntersectionObserver(handleIntersectionEvent, options);
            
            this.blockEls.forEach(el => observer.observe(el));
        },
    },
    mounted() {
        this.calculateAdjustedBlockPositions();
        this.initObserver();
        
    },
    created() {
        window.addEventListener('scroll', throttle(() => this.addStickyNavbar(), 200));
    }
};

</script>
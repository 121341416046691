<template>
    <header id="domov" class="header-section">

        <div class="header-section__top"></div>
        <div class="header-section__middle">
            <div class="header-section__titles">
                <h1 class="heading-primary heading-primary--hero">SERVIS A ÚDRŽBA KOTLOV</h1>
                <h2 class="heading-secondary heading-secondary--hero">Profesionálne služby od majstrov s dlhoročnými skúsenosťami</h2>
                <a href="#kontakt"><custom-button button_style="primary" button_spec="hero">Kontaktujte nás</custom-button></a>
            </div>
        </div>
        <div class="header-section__bottom"></div>

    </header>
</template>

<script>

export default { 
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
    
}
</script>
<template>
    <section  id="sluzby" class="services">
        <img class="services__illustration" src="../assets/SVG/worker1.svg" alt="Engineer repairing boiler" />
        <img class="services__illustration" src="../assets/SVG/worker2.svg" alt="Engineer repairing boiler" />
        <div class="services__inner">
            <div class="services__title"><h1 class="heading-primary heading-primary--services">NAŠE SLUŽBY</h1></div>           
        </div>
        <div class="services__blocks">
            <div class="services__block"  data-aos="fade-up" data-aos-duration="1000">
                <div class="services__block-title">
                    <div class="services__icon"><img src="../assets/SVG/tools.svg" alt="Engineer repairing boiler" /></div>
                    <h2 class="heading-secondary heading-secondary--bold">Servis a oprava</h2>
                </div>
                <span>Záručný a pozáručný servisu obsahuje: uvedenie do prevádzky, opravu a údržbu zdrojov vykurovania, výmenu pokazených a opotrebovaných a náhradných dielov, profilaktickú údržbu a nastavenie zdrojov vykurovania. </span>
            </div>
            <div class="services__block" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div class="services__block-title">
                    <div class="services__icon"><img src="../assets/SVG/house.svg" alt="Engineer repairing boiler" /></div>
                    <h2 class="heading-secondary heading-secondary--bold">Predaj a montáž</h2>        
                </div>
                <span>V rámci našich služieb vám rovnako zabezpečíme predaj a následnú montáž plynových kotlov, pretlakových horákov a tepelných čerpadiel pre ktoré následne garantujeme záručný servis rýchlo a odborne.  </span>
            </div>
            <!-- Servis plynových kotlov pretlakových horákov a tepelných čerpadiel obsahuje: uvedenie do prevádzky,oprava a údržba zdrojov vykurovania. Výmena pokazených a opotrebovaných náhradných dielov. Profilaktická údržba a nad stavenie zdrojov vykurovania. Vystavenie protokolu so servisného zásahu.

-Záručný a po záručný servis plynových kotlov značiek: Buderus,
Junkers, Intergas, Geminox,Viessmann,Hamworthy

-Záručný a pozáručný servis pretlakových horákov: Giersch, APH, Weishaupt,Wiessmann,Bentone,

-Záručný a pozáručný servis tepelných čerpadiel Buderus

-Záručný a pozáručný servis regulácií vykurovania

Naša firma ponúka predaj a montáž plynových kotlov,pretlakových horákov a tepelných čerpadiel. -->


            <div class="services__block" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                <div class="services__block-title">
                    <div class="services__icon"><img src="../assets/SVG/certificate.svg" alt="Engineer repairing boiler" /></div>
                    <h2 class="heading-secondary heading-secondary--bold">Značky zariadení</h2>        
                </div>
                <span>Špecializujeme sa na servis plynových kotlov značiek: Buderus, Junkers, Intergas, Geminox, Viessmann, Hamworthy; pretlakových horákov značiek: Giersch, APH, Weishaupt, Wiessmann, Bentone; a tepelných čerpadiel Buderus. </span>
            </div>
        </div>
    </section> 
</template>
